(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name rcrFrontend.service:Aside
   *
   * @description
   *
   */
  angular
    .module('rcrFrontend')
    .service('Aside', Aside);

  function Aside($timeout, Messages) {
    var self = this;
    self.get = function () {
      return 'Aside';
    };


	self.showAside = []; //don't ask me why I made it an array but it works this way, it didn't work as a boolean so just leave it okay?

	self.expandAside = function(){
		self.expanded = true;
	}
	self.shrinkAside = function(){
		self.expanded = false;
	}

	self.openAside = function(reqId){
		var isopen = self.showAside.length;
		if (isopen == 0){
			self.showAside.push('true');
		}
		
		if (self.reqId != reqId){
			self.reqId = reqId;
		}else if (isopen > 0){
			self.showAside.splice(0,1);
		}
		
		

	}


	self.closeAside = function(){
		self.shrinkAside();
		self.showAside.splice(0,1);
	}
  }
}());
